.login
  height: 100%
  background-color: $medium-violet

  .ant-alert-warning
    margin-bottom: 24px

  .ant-col
    display: flex
    flex-direction: column
    justify-content: center

  &__card
    height: 100%
    box-shadow: 0px 24px 30px -15px rgba(0, 0, 0, 0.5)
    border: 1px solid #E0DBD1
    background-color: #fff

    @media (min-width: 768px)
      height: 474px
      border-radius: 16px

    &-copyright
      padding: 10px
      text-align: center
      color: #636363
      font-size: 12px
      line-height: 16px

    &-title
      text-align: center
      margin-bottom: 33px !important

    .ant-card-body
      height: 100%
      padding: 0
      .ant-row
        height: 474px

    &-about
      background-color: #EDEDED
      background-image: url("../assets/intro.svg")
      background-repeat: no-repeat
      background-position: center center
      background-size: 80%
      position: relative
      border-radius: 16px
      display: none !important

      @media (min-width: 768px)
        display: flex !important

      img
        position: absolute
        bottom: 40px
        left: 40px

  .login-form
    .ant-input
      border-radius: 16px
      padding: 12px 10px
    .ant-btn-primary
      min-height: 48px
      background: $dark-violet
      box-shadow: 0px 16px 20px -10px rgba(31, 9, 54, 0.6)
      border-radius: 8px
      border-color: $dark-violet
      font-weight: bold
